import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Card = ({ image, title, description }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Bir kere tetiklenmesi yeterli
    threshold: 0.4,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50, rotateX: -15 }}
      animate={inView ? { opacity: 1, y: 0, rotateX: 0 } : {}}
      transition={{ duration: 0.1, ease: "easeOut" }}
      whileHover={{
        scale: 1.05,
        background: "linear-gradient(45deg, #FFFFFF, #BCBCBC)",
        backgroundSize: "200%", // Gradient büyütme
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
      }}
      className="relative flex flex-col items-center justify-between bg-white/30 backdrop-blur-sm rounded-lg shadow-2xl p-6 space-y-4 hover:shadow-3xl transition-all duration-300"
    >
      <motion.img
        src={image}
        alt={title}
        className="w-full h-48 object-fill rounded-md transform transition-transform duration-300 hover:scale-105"
      />
      <motion.h3
        className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-black to-red-700"
        whileHover={{ backgroundPosition: "200%" }}
        transition={{ duration: 0.5 }}
      >
        {title}
      </motion.h3>
      <motion.p
        className="text-gray-900 text-center"
        dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, "<br />") }}
      />
    </motion.div>
  );
};

const Cards2 = () => {
  const cards = [
    {
      image: "/images/firstcaro/4.jpg",
      title: "CTP levha üretiminde dünya markası",
      description:
        "Yücel Grubu 1949 yılından beri kompozit sektöründe, 1971 yılından beri de çatı kaplama malzemelerinde geliştirdiği pazar, malzeme ve ürün deneyimiyle 1983 yılında Fibrosan’ı kurdu.<br /><br /> 2000’li yıllarda Türkiye CTP levha pazarında liderlik hedefine ulaşan Fibrosan, dünya CTP levha üretimindeki en ileri standart ve teknikleri Türkiye’ye taşıyarak kendi üretimi ve markası ile dünya CTP pazarının seçkin üreticileri arasında yerini aldı.",
    },
    {
      image: "/images/firstcaro/7.jpg",
      title: "Sıvı ve katı depolamada CTP ile yaratılan dünya projeleri",
      description:
        "Yücel Grubu’nun 60 yıllık deneyimi ile kurduğu Fiberplast, grubun kompozit sektöründeki yenilikçi ve ilerici bakışı simgeleyen bir başka yatırımdır.<br /><br />Fiberplast; CTP tank, silo ve havuz alanındaki uzmanlığı, dünya standartlarındaki kalitesi ve özel proje gücüyle gıda, hayvancılık, kimya, spor ve rekreasyon, su arıtma alanlarında Türkiye’nin en önemli tesis ve markalarına, dünyanın önemli üretici ve projelerine hizmet veriyor.",
    },
    {
      image: "/images/firstcaro/8.jpg",
      title: "Türkiye kompozit sektörünün malzeme tedarikçisi",
      description:
        "Yücel Grubu’nun kompozit sektöründeki en köklü deneyiminin ürünü olarak 1983 yılında kurulan Yücel Kompozit, Türk kompozit üreticilerinin hammadde, makina ve ekipman ihtiyaçlarını en yüksek kaliteli ürünlerle karşılıyor.<br /><br /> Türkiye ve dünyanın lider kompozit malzeme ve ekipman üreticilerinin temsilciliğini de yürüten Yücel Kompozit, sektördeki tüm üreticiler için her türlü çözümü tek adreste topluyor.",
    },
    {
      image: "/images/cards/4.jpg",
      title: "Fibrosan markası Almanya’da",
      description:
        "Yüksek kalite standartlarıyla Alman CTP pazarının seçici ve titiz ortamında haklı bir ilgi gören Fibrosan, 1999 yılından beri Almanya’da kendi şirketi ile faaliyet gösteriyor.",
    },
    {
      image: "/images/cards/5.jpg",
      title: "İngiltere’de Fibrosan",
      description:
        "Fibrosan U.K. Ltd., Atlantik aşırı pazarların da kabul ettiği kalite seçiciliği ve inovasyon anlayışıyla tanınan İngiltere’de 2007 yılından beri hizmet veriyor.",
    },
    {
      image: "/images/cards/6.jpg",
      title: "ABD’de Fibrosan",
      description:
        "100% Fibrosan yatırımı olarak kurulan Fibrosan Incorporated Chicago yakınlarındaki Elkhart’ta faaliyete geçti.<br /><br />Fibrosan Inc. Kuzey Amerika pazarında büyük ilgi gören ve giderek kıtaya yayılan Fibrosan ürünlerini Amerikan üreticilerine daha hızlı ulaştırıyor.",
    },
  ];

  return (
    <div className="min-h-screen p-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {cards.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Cards2;
