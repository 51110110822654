// src/components/GTranslate.js
import React, { useEffect } from 'react';

const GTranslate = () => {
  useEffect(() => {
    // Add GTranslate widget
    const script = document.createElement('script');
    script.src = 'https://cdn.gtranslate.net/widgets/latest/dwf.js';
    script.defer = true;

    const gtranslateSettings = document.createElement('script');
    gtranslateSettings.innerHTML = `
      window.gtranslateSettings = {
        "default_language": "tr",
        "native_language_names": true,
        "languages": ["tr", "en", "fr", "nl", "es", "zh-CN", "it"],
        "globe_color": "#ffffff",
        "wrapper_selector": ".gtranslate_wrapper",
        "horizontal_position": "center",
        "vertical_position": "top"
      };
    `;

    document.body.appendChild(gtranslateSettings);
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(gtranslateSettings);
    };
  }, []);

  return (
    <div className="gtranslate_wrapper pl-52 sm:pl-96">
      {/* The widget will be placed here */}
    </div>
  );
};

export default GTranslate;
