import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { motion } from "framer-motion";


const slides = [
  {
    image: "/images/secaro/1.jpg",
    description: "1940'ların sonunda İzmir Fuarı dünyayı İzmir'e getiriyordu",
  },
  {
    image: "/images/secaro/2.jpg",
    description:`1950'lerde dünya fiberglas kompozitle ilgilenmeye başlamıştı`
  },
  {
    image: "/images/secaro/4.jpg",
    description: "60'larda fiberglas karavanlar moda oldu",
  },
  {
    image: "/images/secaro/3.jpg",
    description: "1970 döneminde kompozit tekneler yaygınlaştı",
  },
  {
    image: "/images/secaro/7.png",
    description: "1980'lerde çatılar Fibrosan CTP ile tanıştı",
  },
  {
    image: "/images/secaro/5.png",
    description: "90'larda şeffaf çatılar dönemi başladı",
  },
  {
    image: "/images/secaro/8.jpg",
    description: "2000'li yıllarda Fibrosan'la CTP hijyeni",
  },
  {
    image: "/images/secaro/9.webp",
    description: "2013...Fibrosan dünya projelerinde",
  },
]; 

const Carousel1 = () => {
  return (
    <div className="min-h-screen flex items-center font-montserrat sm:mb-60 lg:pt-20 justify-center p-4 pb-10 md:p-8 relative">
      <motion.div
        className="absolute inset-0 z-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 3}}
        transition={{ duration: 1.5 }}
      ></motion.div>

      <motion.div
        initial={{ x: -50, opacity: 0 }} // Yukarıdan başla, şeffaf olarak
        animate={{ x: 0, opacity: 1 }}   // Normal konuma ve tam görünüme geç
        transition={{ duration: 2, ease: "anticipate" }} // Yumuşak bir geçiş süresi
        className="w-full max-w-6xl relative z-10 rounded-2xl"
      >
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView="auto"
          loop={true}
          navigation={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          coverflowEffect={{
            rotate: 30,
            stretch: 100,
            depth: 200,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper-container"
        >
          {slides.map((slide, index) => (
            <SwiperSlide
              key={index}
              className="relative flex justify-center items-center"
            >
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                className="rounded-2xl w-[90vw] h-[55vh] sm:h-[40vh] md:h-[50vh] lg:h-[80vh] xl:w-[70vw] xl:h-[75vh] object-fill"
              />
              {slide.description && (
                <div className="absolute bottom-8 sm:bottom-12 left-36 bg-black/55 text-white text-xs sm:text-sm md:text-lg px-2 sm:px-4 py-1 sm:py-2 rounded-lg">
                  <h2 className="text-sm md:text-lg font-bold">
                    {slide.title}
                  </h2>
                  <p className="text-xs sm:text-sm">{slide.description}</p>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </motion.div>
    </div>
  );
};

export default Carousel1;


















