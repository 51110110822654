import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import "@heroicons/react/solid";
import "@heroicons/react/outline";
import "tailwindcss/tailwind.css";
import GTranslate from "../GTanslate";

export default function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendEmail = (data) => {
    emailjs
      .send(
        "service_1pbcy0e", // EmailJS Service ID (Outlook)
        "template_dmnzz7c", // EmailJS Template ID
        {
          to_name: data.name, // Form data
          from_name: "cagatay", // Your sender's name
          message: data.message, // Form data
          to_email: data.email, // Form data
          phone: data.phone, // Form data
        },
        "OGT-hJhnDXGP_dC1a" // Public Key (API Key)
      )
      .then(
        (result) => {
          alert("Message Sent!"); // Success message
        },
        (error) => {
          alert("An error occurred, Please try again"); // Error message
        }
      );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="bg-gradient-to-t from-gray-700 via-gray-200 to-gray-700 flex items-center font-montserrat justify-center">
        <div className="pt-20">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="flex flex-col md:flex-row items-center justify-center w-full max-w-6xl space-y-6 md:space-y-0 md:space-x-6"
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5 }}
              className="glassmorphism p-10 rounded-lg shadow-lg w-full max-w-lg"
              style={{
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              }}
            >
              <h2 className="text-4xl font-bold text-center text-red-600 mb-4">
                Bize Ulaşın
              </h2>

              <p className="text-center text-black mb-6">
                Sorularınız veya önerileriniz için bizimle iletişime
                geçebilirsiniz. Aşağıdaki formu doldurarak mesajınızı bize
                iletebilirsiniz. En kısa sürede size geri dönüş yapacağız.
              </p>

              <form onSubmit={handleSubmit(sendEmail)} className="space-y-4">
                <div>
                  <input
                    {...register("name", { required: true })}
                    type="text"
                    placeholder="Ad Soyad"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none shadow-sm shadow-red-900 bg-opacity-60 text-gray-700"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-sm">
                      Name is required
                    </span>
                  )}
                </div>

                <div>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    placeholder="E-mail"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none shadow-sm shadow-red-900  bg-opacity-60 text-gray-700"
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm">
                      Email is required
                    </span>
                  )}
                </div>

                <div>
                  <input
                    {...register("phone", { required: true })}
                    type="text"
                    placeholder="Numaranız"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none shadow-sm shadow-red-900  bg-opacity-60 text-gray-700"
                  />
                  {errors.phone && (
                    <span className="text-red-500 text-sm">
                      Phone is required
                    </span>
                  )}
                </div>

                <div>
                  <textarea
                    {...register("message", { required: true })}
                    placeholder="Mesajınız"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none bg-white shadow-sm shadow-red-900 bg-opacity-60 text-gray-700"
                    rows="4"
                  />
                  {errors.message && (
                    <span className="text-red-500 text-sm">
                      Message is required
                    </span>
                  )}
                </div>

                <motion.button
                  whileHover={{ scale: 1.05, backgroundColor: "#ff0000" }}
                  className="bg-red-700 text-white px-6 py-2 rounded-lg w-full mt-4"
                  type="submit"
                >
                  Gönder
                </motion.button>
              </form>
            </motion.div>
          </motion.div>
        </div>
      </div>
      <GTranslate />
    </div>
  );
}
