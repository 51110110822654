import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import Coordinate from './components/pages/Coordinate';
import Footer from './components/Footer';
import History from './components/pages/History';
import Enterprise from './components/pages/Enterprise';
import Awards from './components/pages/Awards';

function App() {
  return (
    <Router>
     
      {/* Navbar */}
      <Navbar />
      <div > 
        
        <Routes>
          <Route path="/" element={<Home />} />    
          <Route path="/iletisim" element={<Contact />} />
          <Route path="/konumlar" element={<Coordinate />} />

          
          <Route path="/about/history" element={<History />} />

          <Route path="/about/enterprise" element={<Enterprise />} />
          <Route path="/about/awards" element={<Awards />} />


       
     
        </Routes>
      </div>
      <Footer />

    </Router>
  );
}

export default App;
