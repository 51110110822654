import React, { useEffect } from 'react'
import Cards3 from '../Cards3'
import GTranslate from '../GTanslate';
import Carousel3 from '../Carousel3';

const Enterprise = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    
  return (
    <div className='font-montserrat bg-gradient-to-t from-gray-700 via-gray-200 to-gray-700'>
        <Carousel3 />
        <Cards3 />
        <GTranslate />
    </div>
  )
}

export default Enterprise