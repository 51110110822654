import React, { useEffect } from "react";
import {
  BriefcaseIcon,
  GlobeAltIcon,
  ChartBarIcon,
  LightningBoltIcon,
  ShieldCheckIcon,
  CogIcon,
  UserGroupIcon,
  TruckIcon,
  OfficeBuildingIcon,
  CashIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline";
import { motion } from "framer-motion";
import GTranslate from "../GTanslate";

// Card Component
const CardSection = ({ title, description, Icon }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 1.02 }}
    className="relative flex flex-col items-center justify-center text-center bg-gradient-to-br from-gray-100 via-gray-200 to-gray-300 rounded-lg shadow-lg p-6 w-full md:w-80 lg:w-96 mx-auto my-4"
  >
    <motion.div
      className="p-4 bg-gray-900 text-white rounded-full shadow-lg mb-4"
      whileHover={{ rotate: 360 }}
      transition={{ duration: 0.8 }}
    >
      <Icon className="h-12 w-12" />
    </motion.div>
    <motion.h3
      className="text-2xl font-bold text-red-800  mb-3"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.6 }}
    >
      {title}
    </motion.h3>
    <motion.p
      className="text-gray-600"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4, duration: 0.6 }}
    >
      {description}
    </motion.p>
  </motion.div>
);

// Main Component with 3 groups
const Awards = () => {


  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  // Data for all groups
  const groups = [
    {
      title: "EBSO Ödülleri 2012",
      description: "Grup şirketlerinden Fibrosan A.Ş. ve Fiberplast Ltd. Şti. 2011 yılı performansıyla 2012’de Ege Bölgesi Sanayi Odası tarafından 5 dalda ödüllendirilmiştir.",
      cards: [
        { description: "Fibrosan A.Ş", title: "Kompozit grubunda en yüksek ihracat", Icon: CogIcon },
        { description: "Fibrosan A.Ş", title: "Kompozit grubunda 2. en yüksek üretim", Icon: UserGroupIcon },
        { description: "Fibrosan A.Ş", title: "Kompozit grubunda 2. en yüksek istihdam", Icon: TruckIcon },
        { description: "Fibrosan A.Ş", title: "Kompozit grubunda 3. en yüksek ihracat", Icon: OfficeBuildingIcon },
        { description: "Fibrosan A.Ş", title: "Yurtdışı satış performansı başarısı", Icon: CashIcon },
    
      ],
    },
    {
      title: "EBSO Ödülleri 2013",
      description: "Grup şirketlerinden Fibrosan A.Ş., HLM International ve Fiberplast Ltd. Şti. 2012 yılı performansıyla 2013’te Ege Bölgesi Sanayi Odası tarafından 6 dalda ödüllendirilmiştir.",
      cards: [
        { description: "Fibrosan - Panolux", title: "İnovasyon ödülü", Icon: CogIcon },
        { description: "Fibrosan A.Ş", title: "Kompozit grubunda 2. en yüksek üretim", Icon: UserGroupIcon },
        { description: "Fibrosan A.Ş", title: "Kompozit grubunda 2. en yüksek istihdam", Icon: TruckIcon },
        { description: "HLM", title: "Kompozit grubunda 2. en yüksek ihracat", Icon: OfficeBuildingIcon },
        { description: "Fiberplast", title: "Kompozit grubunda 3. en yüksek ihracat", Icon: CashIcon },
        { description: "Fibrosan A.Ş", title: "Tüm üye KOBİ’ler arasında 5. en yüksek ihracat", Icon: TrendingUpIcon },
      ],
    },
    {
      title: "EBSO Ödülleri 2014",
      description: "Grup şirketlerinden Fibrosan A.Ş. ve HLM International Ltd. Şti. 2013 yılı performansıyla 2014’te Ege Bölgesi Sanayi Odası tarafından 6 dalda ödüllendirilmiştir.",
      cards: [
        { description: "Fibrosan", title: "Kompozit grubunda en yüksek yatırım", Icon: BriefcaseIcon },
        { description: "Fibrosan", title: "Kompozit grubunda 2. en yüksek ihracat", Icon: GlobeAltIcon },
        { description: "Fibrosan", title: "Kompozit grubunda 2. en yüksek istihdam", Icon: ShieldCheckIcon },
        { description: "HLM", title: "Taşıt Sanayi grubunda 2. en yüksek üretim", Icon: CashIcon },
        { description: "HLM", title: "Taşıt Sanayi grubunda 2. en yüksek ihracat", Icon: LightningBoltIcon },
        { description: "Fiberplast", title: "Kompozit grubunda 3. en yüksek ihracat", Icon: ChartBarIcon },
      ],
    },
  ];

  return (
    <div>
    <div className="bg-gradient-to-t from-gray-700 via-gray-200 to-gray-700 min-h-screen font-montserrat py-16 px-4">

      
      {groups.map((group, index) => (
        <div key={index} className="my-16">
          
          <motion.h2
            className="text-4xl font-bold text-red-500  text-center mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.6 }}
          >
            {group.title}
          </motion.h2>
          
          <motion.p
            className="text-lg text-white text-center mb-12 max-w-4xl mx-auto"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.6 }}
          >
            {group.description}
          </motion.p>
          <motion.div
            className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            {group.cards.map((card, idx) => (
              <CardSection
                key={idx}
                title={card.title}
                description={card.description}
                Icon={card.Icon}
              />
            ))}
          </motion.div>
        </div>

        
      ))}
    </div>
<GTranslate />
    </div>
  );
};

export default Awards;
