import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform, useMotionValue } from 'framer-motion';
import { PlusCircleIcon } from '@heroicons/react/outline';

const Milestone = () => {
  const milestones = [
    { year: '1949’dan 60’lara...', description: '1949’da fiberglasın otomotiv ve tekne üretiminde kullanılmaya başlamasıyla Haydar Yücel ticari faaliyetlerine adım attı. 50’ler ve 60’larda yapı sektöründe çatı malzemeleri, boya ve makine ekipmanları pazarlayarak CTP ile ilgisini sürdürdü.', image: '/images/history/1.jpeg' },
    { year: '70’ler ve CTP', description: '1970’te Erdem ve Erdal Yücel’in katılımıyla firma CTP sektörüne yönelmeye karar verdi. 1971’den itibaren CTP pazarlamasını Türkiye genelinde yaygınlaştırarak sektörde güçlü bir konum kazandı.', image: '/images/history/2.jpg' },
    { year: '80’ler... Üretime geçiş', description: '1983 yılında Yücel Grubu, Fibrosan CTP Sanayi ve Ticaret A.Ş.’yi kurarak üretime geçti. 1987’de Fiberplast Plastik Ürünler Ltd. Şti. kurulmasıyla CTP silo ve tank üretimi bu alanda genişledi.', image: '/images/history/3.jpg' },
    { year: '90’lar biterken Avrupa’da ilk şirket', description: '1999’da Yücel Grubu, Almanya’da Fibrosan GmbH’yi kurarak Avrupa pazarına doğrudan açıldı ve uluslararası pazardaki varlığını güçlendirdi.', image: '/images/history/4.jpg' },
    { year: '2000’ler, İngiltere ve Atlantik ötesi', description: 'Fibrosan, İngiltere ve Amerika’da ürünleriyle büyük ilgi topladı. 2007’de İngiltere’de Fibrosan UK Ltd. kurularak Avrupa’daki faaliyetler genişledi.', image: '/images/history/5.jpeg' },
    { year: '2010 ve sonrası, dünya projeleri', description: 'Fibrosan ve Fiberplast, 2010 sonrası dev projelerde yer aldı ve uluslararası ödüller kazandı. Amerika pazarındaki başarılarını Fibrosan Incorporated ile pekiştirdiler.', image: '/images/history/6.jpg' }
  ];

  const { scrollYProgress } = useScroll();
  const lineColor = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    ['#d1d5db', '#6b7280', '#374151']
  );

  const [showIcon, setShowIcon] = useState(false);

  // Mouse hareketlerini izlemek için motion değerleri
  const mouseX = useMotionValue(30);
  const mouseY = useMotionValue(30);

  // Mouse hareketiyle resimlerin pozisyonunu değiştiren fonksiyon
  const handleMouseMove = (event) => {
    mouseX.set(event.clientX);
    mouseY.set(event.clientY);
  };

  // Mouse pozisyonuna göre resimlerin hareketini belirleyen transform
  const imageX1 = useTransform(mouseX, [0, window.innerWidth], [-50, 50]);
  const imageY1 = useTransform(mouseY, [0, window.innerHeight], [-50, 50]);

  const imageX2 = useTransform(mouseX, [0, window.innerWidth], [50, -50]);
  const imageY2 = useTransform(mouseY, [0, window.innerHeight], [50, -50]);

  const imageX3 = useTransform(mouseX, [0, window.innerWidth], [-100, 100]);
  const imageY3 = useTransform(mouseY, [0, window.innerHeight], [100, -100]);

  useEffect(() => {
    const handleScroll = () => {
      const screenWidth = window.innerWidth;
      let scrollThresholdStart;
      let scrollThresholdEnd;

      if (screenWidth >= 1024) {
        scrollThresholdStart = 0.13;
        scrollThresholdEnd = 1.2;
      } else if (screenWidth >= 768) {
        scrollThresholdStart = 0.15;
        scrollThresholdEnd = 0.8;
      } else {
        scrollThresholdStart = 0.13;
        scrollThresholdEnd = 0.9;
      }

      const unsubscribe = scrollYProgress.onChange((latest) => {
        if (latest > scrollThresholdStart && latest < scrollThresholdEnd) {
          setShowIcon(true);
        } else {
          setShowIcon(false);
        }
      });

      return () => unsubscribe();
    };

    handleScroll();
    window.addEventListener('resize', handleScroll);

    return () => window.removeEventListener('resize', handleScroll);
  }, [scrollYProgress]);

  return (
    <div
      className="relative flex justify-center font-montserrat w-full"
      onMouseMove={handleMouseMove}
    >

      <div className="absolute z--20 w-full h-full overflow-hidden">
        <motion.img
          src="/images/abstract/factory1.png"
          alt="Factory Image 1"
          className="absolute top-0 sm:left-52 left-16  w-[30rem] h-[30rem] sm:w-[100vh] sm:h-[100vh] object-fill"
          style={{ x: imageX1, y: imageY1 }}
        />
        <motion.img
          src="/images/abstract/factory2.png"
          alt="Factory Image 2"
          className="absolute top-1/3 lg:right-0 right-36 w-[30rem] h-[30rem] sm:w-[100vh] sm:h-[100vh]  object-fill"
          style={{ x: imageX2, y: imageY2 }}
        />
        <motion.img
          src="/images/abstract/factory3.png"
          alt="Factory Image 3"
          className="absolute bottom-0 right-1/3 w-[30rem] h-[30rem] sm:w-[60vh] sm:h-[60vh] object-fill"
          style={{ x: imageX3, y: imageY3 }}
        />
      </div>

      <motion.div
        className="absolute  h-[calc(300vh-70px)] lg:h-[calc(400vh-70px)] xl:h-[calc(180rem-70px)] w-[0.10rem]"
        style={{
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: lineColor,
        }}
        initial={{ opacity: 0.5 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      />

      {showIcon && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
          <PlusCircleIcon className="h-6 w-6 text-red-600/50 bg-slate-200/70 p-1 rounded-full" />
        </div>
      )}

      <div className="flex flex-col items-center mb-4  py-10 w-full px-4 sm:px-8 lg:px-16">
        {milestones.map((milestone, index) => (
          <div key={index} className="mb-10 w-full flex justify-center relative">
            <div
              className={`flex md:w-1/2 lg:w-1/2 xl:w-1/2 px-4 ${
                index % 2 === 0 ? 'justify-end ml-36' : 'justify-start mr-36'
              }`}
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-white shadow-xl p-6 rounded-xl max-w-80 border shadow-black border-gray-200"
              >
                <img
                  src={milestone.image}
                  alt={`${milestone.year} milestone`}
                  className="rounded-lg w-full h-40 object-cover mb-4"
                />
                <div className="mt-4 text-center">
                  <h3 className="text-lg sm:text-xl lg:text-2xl font-bold mb-2">{milestone.year}</h3>
                  <p className="text-xs sm:text-sm lg:text-base text-gray-600 whitespace-pre-line">{milestone.description}</p>
                </div>
              </motion.div>
            </div>

            <div className="absolute left-1/2 transform -translate-x-1/2 mt-6">
              <motion.div
                initial={{ opacity: 0.3 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
                className="bg-red-800 text-white text-sm p-4 rounded-full w-1 h-1 flex items-center justify-center "
              >
                {index + 1}
              </motion.div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Milestone;
