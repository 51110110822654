import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  MenuIcon,
  XIcon,
  ChevronDownIcon,
  PhoneIcon,
  HomeIcon,
  DesktopComputerIcon,
  DeviceMobileIcon,
  BriefcaseIcon,
  LinkIcon,
} from '@heroicons/react/outline';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleToggle = (index) => {
    if (!navItems[index].subItems) {
      handleMenuClose();
    } else {
      setActiveIndex(activeIndex === index ? null : index);
    }
  };

  const handleMenuClose = () => {
    setIsOpen(false);
    setActiveIndex(null);
  };

  const navItems = [
    { name: 'Anasayfa', path: '/', icon: <HomeIcon className="h-5 w-5 mr-2" /> },
    {
      name: 'Hakkımızda',
      icon: <DesktopComputerIcon className="h-5 w-5 mr-2" />,
      subItems: [
        { name: 'Tarihçe', path: '/about/history', icon: <DeviceMobileIcon className="h-5 w-5 mr-2" /> },
        { name: 'Girişim ve Yatırımlar', path: '/about/enterprise', icon: <DeviceMobileIcon className="h-5 w-5 mr-2" /> },
        { name: 'Ödüller', path: '/about/awards', icon: <DeviceMobileIcon className="h-5 w-5 mr-2" /> },
      ],
    },
    {
      name: 'Şirketler',
      icon: <DesktopComputerIcon className="h-5 w-5 mr-2" />,
      subItems: [
        { name: 'Fibrosan', icon: <LinkIcon className="h-5 w-5 mr-2" />, path: 'https://fibrosan.com.tr'},
        { name: 'Fiberplast', icon: <LinkIcon className="h-5 w-5 mr-2" />, path: 'http://www.fiberplast.com.tr/?l=tr'},
        { name: 'Yücel Kompozit',  icon: <LinkIcon className="h-5 w-5 mr-2" />, path: 'https://www.yucelkompozit.com.tr'},
      ],
    },
    { name: 'İletişim', path: '/iletisim', icon: <PhoneIcon className="h-5 w-5 mr-2" /> },
    { name: 'Konumlar', path: '/konumlar', icon: <BriefcaseIcon className="h-5 w-5 mr-2" /> },
  ];

  return (
    <nav className="bg-[#751515] bg-opacity-90 font-poppins text-white fixed w-full z-50 shadow-md">
       
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-12">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold flex items-center font-sans" onClick={handleMenuClose}>
              <img src="/images/logo.jpg" alt="" className="h-10 rounded-lg w-42 shadow-red-50 shadow-8xl" />
            </Link>
          </div>
          <div className="hidden md:flex">
            <div className="ml-10 flex items-baseline space-x-4">
              {navItems.map((item, index) => (
                <div key={index} className="relative">
                  {item.subItems ? (
                    <button
                      onClick={() => handleToggle(index)}
                      className="px-3 py-2 rounded-md text-sm font-medium hover:bg-blue-500 hover:text-white transition-all duration-300 ease-in-out flex items-center"
                    >
                      {item.icon}
                      {item.name}
                      <ChevronDownIcon
                        className={`h-4 w-4 ml-1 transform transition-transform duration-200 ${
                          activeIndex === index ? 'rotate-180' : ''
                        }`}
                      />
                    </button>
                  ) : (
                    <Link
                      to={item.path}
                      className="px-3 py-2 rounded-md text-sm font-medium hover:bg-blue-500 hover:text-white transition-all duration-300 ease-in-out flex items-center"
                      onClick={handleMenuClose}
                    >
                      {item.icon}
                      {item.name}
                    </Link>
                  )}
                  <AnimatePresence>
                    {item.subItems && activeIndex === index && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute left-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg py-2 border border-gray-200"
                      >
                        {item.subItems.map((subItem, subIndex) => (
                          <Link
                            key={subIndex}
                            to={subItem.path}
                            className="block px-4 py-2 hover:bg-blue-500 hover:text-white transition-all duration-300 ease-in-out flex items-center"
                            onClick={handleMenuClose}
                          >
                            {subItem.icon}
                            {subItem.name}
                          </Link>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-700 transition duration-300"
            >
              {isOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="px-2 pt-2 pb-3 space-y-1 sm:px-3"
          >
            {navItems.map((item, index) => (
              <div key={index} className="relative">
                {item.subItems ? (
                  <button
                    onClick={() => handleToggle(index)}
                    className="block px-3 py-2 rounded-md text-base font-medium  hover:bg-blue-500 hover:text-white transition-all duration-300 w-full text-left flex items-center"
                  >
                    {item.icon}
                    {item.name}
                    <ChevronDownIcon
                      className={`h-4 w-4 ml-auto transform transition-transform duration-200 ${
                        activeIndex === index ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                ) : (
                  <Link
                    to={item.path}
                    className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-500 hover:text-white transition-all duration-300 w-full flex items-center"
                    onClick={handleMenuClose}
                  >
                    {item.icon}
                    {item.name}
                  </Link>
                )}
                {item.subItems && activeIndex === index && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    className="ml-4 space-y-1 overflow-hidden"
                  >
                    {item.subItems.map((subItem, subIndex) => (
                      <Link
                        key={subIndex}
                        to={subItem.path}
                        className="block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-500 hover:text-white transition-all duration-300 flex items-center"
                        onClick={handleMenuClose}
                      >
                        {subItem.icon}
                        {subItem.name}
                      </Link>
                    ))}
                  </motion.div>
                )}
              </div>
            ))}
          </motion.div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
