import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-gray-700 font-montserrat to-black pt-12 pb-8 text-white">
      {/* Container */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        
        {/* Logo Section */}
        <div className="flex items-center justify-center sm:col-span-1 lg:col-span-1">
          <img src="/images/logo.jpg" alt="Logo" className=" h-24 w-auto bg-white rounded-xl" />
        </div>

       
        <div className="text-white sm:col-span-1 lg:col-span-1 text-center shadow-inner shadow-white rounded-3xl p-5 sm:text-left">
          <h3 className="text-red-700 text-xl font-semibold mb-2">Fibrosan A.Ş.</h3>
          <p className="text-gray-400 text-sm mb-2">Çınarköy, İzmir TR, Madenaltı Mevkii No:24, 35370 Kemalpaşa</p>
          <p className="text-gray-400 text-sm">☎ 0232 479 16 73</p>
        </div>

       
        <div className="text-white shadow-inner shadow-white rounded-3xl p-5 sm:col-span-1 lg:col-span-1 text-center sm:text-left">
          <h3 className="text-red-700 text-xl font-semibold mb-2">Yucel Kompozit</h3>
          <p className="text-gray-400 text-sm mb-2">Kemalpaşa, Kemalpaşa Cd. NO:276, 35060 PINARBAŞI/İzmir</p>
          <p className="text-gray-400 text-sm">☎ 0232 433 60 36</p>
        </div>

       
        <div className="text-white shadow-inner shadow-white rounded-3xl p-5 sm:col-span-1 lg:col-span-1 text-center sm:text-left">
          <h3 className="text-red-700 text-xl font-semibold mb-2">Fiberplast A.Ş.</h3>
          <p className="text-gray-400 text-sm mb-2">Kemalpaşa Mahallesi, 7415. Sk. No:6 D:Pınarbaşı, 35060 Bornova/İzmir</p>
          <p className="text-gray-400 text-sm">☎ 0224 216 16 88</p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 font-poppins mt-8 flex justify-center space-x-6">
        <a href="https://facebook.com" className="text-gray-400 hover:text-blue-500 transition-colors">
          <FaFacebook size={24} />
        </a>
        <a href="https://instagram.com" className="text-gray-400 hover:text-pink-500 transition-colors">
          <FaInstagram size={24} />
        </a>
        <a href="https://linkedin.com" className="text-gray-400 hover:text-blue-700 transition-colors">
          <FaLinkedin size={24} />
        </a>
      </div>


      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8 text-center text-gray-500 text-sm">
        <p>&copy; 2024 Yücel Grubu All Rights Reserved.</p>
        
      </div>
    </footer>
  );
};

export default Footer;
