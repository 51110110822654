import React, { useState, useEffect } from 'react';
import Milestone from '../Milestone';
import Carousel2 from '../Carousel2';
import { ParallaxProvider } from 'react-scroll-parallax';
import GTranslate from '../GTanslate';

const History = () => {

  useEffect(() => {
    window.scrollTo(0, 0); 

   
  }, []);

  const [hideIcon, setHideIcon] = useState(false);

  const handleScroll = () => {
    const carousel = document.getElementById('carousel2');

    if (carousel) {
      const carouselTop = carousel.getBoundingClientRect().top;

      if (carouselTop <= window.innerHeight / 2) {
        setHideIcon(true);
      } else {
        setHideIcon(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='bg-gradient-to-t from-gray-700 via-gray-200 to-gray-700'>
     
      <ParallaxProvider>
        <Carousel2 id="carousel2" />
        <GTranslate />
        <Milestone hideIcon={hideIcon} />
      </ParallaxProvider>
    </div>
  );
};

export default History;
