import React from "react";
import { MapIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";

const MapSection = ({ title, address, mapSrc, bgPattern }) => (
  <motion.div
    whileHover={{ scale: 1.08 }}
    whileTap={{ scale: 1.03 }}
    className={`relative flex flex-col font-montserrat md:flex-row  items-center justify-between my-12 space-y-6 md:space-y-0 md:space-x-8 bg-gradient-to-r from-gray-100/50 via-gray-200/50 to-gray-300/50 shadow-inner shadow-gray-500 rounded-lg p-6 transition-all hover:shadow-2xl overflow-hidden`}
  >
    {/* Abstract Background */}
   

    <motion.iframe
      src={mapSrc}
      className="w-full md:w-1/2 h-64 md:h-80 rounded-lg shadow-lg border-2 border-gray-200 relative z-10 transform-gpu hover:scale-110 transition-transform duration-500 ease-out"
      allowFullScreen=""
      loading="lazy"
      title={title}
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: 0.4, duration: 0.8 }}
    ></motion.iframe>

    <div className="flex flex-col items-start justify-center w-full md:w-1/2 space-y-4 relative z-10">
      <motion.h2
        className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-black to-red-500"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6 }}
      >
        {title}
      </motion.h2>
      <div className="flex items-center space-x-3">
        <motion.div
          className="p-2 bg-black shadow-md rounded-full"
          whileHover={{ scale: 1.2 }}
        >
          <MapIcon className="w-6 h-6 text-red-600" />
        </motion.div>
        <p className="text-lg font-medium text-gray-700">{address}</p>
      </div>
    </div>
  </motion.div>
);

const Coordinate = () => {
  return (
    <div className="bg-gradient-to-t font-montserrat from-gray-700 via-gray-200 to-gray-700 min-h-screen pt-20 py-16 px-4">
      

      <motion.div
        className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.4, duration: 0.8 }}
      >
        <MapSection
          title="Fibrosan A.Ş."
          address="Çınarköy, İzmir TR, Madenaltı Mevkii No:24, 35370 Kemalpaşa"
          mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3171.5616132310784!2d27.314032284691725!3d38.47031847963425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbdc279d2dc21d%3A0x192d41baf69c8f1f!2sMadenalt%C4%B1%20Mevkii%2C%2035370%20%C3%87%C4%B1nark%C3%B6y%2F%C4%B0zmir!5e0!3m2!1str!2str!4v1696672396855!5m2!1str!2str"
     
        />
        <MapSection
          title="Fiberplast A.Ş."
          address="Kemalpaşa Mahallesi, 7415. Sk. No:6 D:Pınarbaşı, 35060 Bornova/İzmir"
          mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.115228736347!2d27.2163586846917!3d38.4625908796366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbdafe90aeb15b%3A0x53a9a034c8a2fb16!2s7415.%20Sk.%20No%3A6%2C%20P%C4%B1narba%C5%9F%C4%B1%2C%2035060%20Bornova%2F%C4%B0zmir!5e0!3m2!1str!2str!4v1696672264855!5m2!1str!2str"
     
        />
        <MapSection
          title="Yücel Kompozit"
          address="Kemalpaşa, Kemalpaşa Cd. NO:276, 35060 PINARBAŞI/İzmir"
          mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.1049391601526!2d27.2221216846917!3d38.46285897963643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbdc7b7b84cbe3%3A0xf435e4b576d3e132!2sKemalpa%C5%9Fa%2C%20Kemalpa%C5%9Fa%20Cd.%20No%3A276%2C%2035060%20Bornova%2F%C4%B0zmir!5e0!3m2!1str!2str!4v1696672489942!5m2!1str!2str"
     
        />
      </motion.div>
    </div>
  );
};

export default Coordinate;
