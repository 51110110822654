import React, { useEffect } from 'react';
import Carousel1 from '../Carousel1';
import Cards2 from '../Cards2';
import GTranslate from '../GTanslate';

const Home = () => {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0); 


   
  }, []);

  return (
    <div className='bg-gradient-to-t from-gray-700 via-gray-200 to-gray-700 font-montserrat'>
     

     
      <GTranslate />
      
     
      <Carousel1 />
      <Cards2 className="sm:max-w-screen" />
    </div>
  );
};

export default Home;
