import React from "react";
import { motion } from "framer-motion";

const Card = ({ title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.98 }}
    className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 shadow-xl rounded-lg p-6 max-w-sm transition-transform transform hover:shadow-2xl"
  >
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.3, duration: 0.6 }}
    >
      <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-black to-red-500 mb-4">
        {title}
      </h2>
      
      {description.split("\n").map((line, index) => (
        <p key={index} className="text-gray-700 text-lg">
          {line}
        </p>
      ))}
    </motion.div>
  </motion.div>
);

const Cards3 = () => {
  const cards = [
    {
      title: "Düz ve oluklu CTP levha üretimi",
      description:
        "Yücel Grubu CTP levha üretimi yatırımını 1983’ten itibaren Fibrosan A.Ş. şirketi ile yönetmektedir.\n Fibrosan A.Ş.’nin İzmir Kemalpaşa ve Pınarbaşı tesislerinde kontinü ve kesintili düz ve oluklu CTP levha üretimi yapılmaktadır.\nFibrosan, 2013 yatırımı olan yeni Hi-Gloss tesislerindeki üretimi robotik teknoloji ile gerçekleştirmektedir.",
    },
    {
      title: "CTP silo, tank ve havuz üretimi",
      description:
        "Grubun özel uzmanlık gerektiren ikinci yatırımı olan CTP depolama üniteleri ve havuzların üretimi Fiberplast’ın İzmir Kemalpaşa’daki fabrikasında yapılmaktadır.\nFiberplast tesislerinde en yeni teknolojiler kullanılmaktadır. Fiberplast, kendi konusunda Türkiye’deki en büyük ve kapsamlı üretim tesisidir.",
    },
    {
      title: "Hammadde tedarik ve pazarlama",
      description:
        "Grubun kompozit sektöründeki ilk yatırımı olan hammadde tedarik ve pazarlaması Yücel Kompozit tarafından yönetilmektedir.\n\nTürkiye ve dünyadan lider hammadde, makina ve ekipman üreticilerinin distribütörlüğünü yürüten Yücel Kompozit, Türkiye’nin her alanda önde gelen kompozit üreticilerinin güvenilir tedarikçisidir.",
    },
  ];

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2, duration: 0.6 }}
      >
        {cards.map((card, index) => (
          <Card key={index} title={card.title} description={card.description} />
        ))}
      </motion.div>
    </div>
  );
};

export default Cards3;
